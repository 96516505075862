// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTranslation } from 'react-i18next'

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const BeclomeContractorCard = () => {
    const { t } = useTranslation(); 
    return (
    <CardStyle sx={{ marginTop: '0px' }}>
        <CardContent>
            <Grid container direction="column" spacing={2} sx={{ marginTop: '0px', marginLeft: '0px' }}>
                <Grid item sx={{ paddingRight: '8px' }}>
                    <Typography variant="h4">{t('become-contractor-card-line1')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                        {t('become-contractor-card-line2')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row">
                        <AnimateButton>
                            <Button variant="contained" color="warning" sx={{ boxShadow: 'none' }} href="/dashboard/become-contractor">
                                {t('become-contractor-card-button')}
                            </Button>
                        </AnimateButton>
                    </Stack>
                </Grid>
            </Grid>
        </CardContent>
    </CardStyle>
    );
}

export default BeclomeContractorCard;
