import PropTypes from 'prop-types';
import { useState, useEffect, forwardRef, Fragment } from 'react';

// material-ui
import { Alert, Grid, Link, Snackbar, Stack, Typography } from '@mui/material';

// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next'
import { gridSpacing } from 'store/constant';
import sanityClient from "sanityClient.js";
import { useTheme } from '@mui/material/styles';
import googlePlay from 'assets/images/google-play.png';
import appStore from 'assets/images/app-store.png';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  // HatenaShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  // LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  // LivejournalShareButton,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  TelegramShareButton,
  TelegramIcon,
  // TumblrShareButton,
  // TwitterShareButton,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
  WhatsappShareButton,
  WhatsappIcon,
  // WorkplaceShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share"
import './index.css'

// ==============================|| CUSTOM SUB CARD ||============================== //

const FooterCard = forwardRef(({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, ...others }, ref) => {

    // console.log('FooterCard() sx:', sx);
    const [snackbar, setSnackbar] = useState(null);
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const handleCloseSnackbar = () => setSnackbar(null);
    // const { t } = useTranslation(); 
    // const username = sessionStorage.getItem('username');
    // const userID = sessionStorage.getItem('userID');
    // const permissions = sessionStorage.getItem('permissions');
    // const token = sessionStorage.getItem('mytoken');
    let lang = localStorage.getItem('allserv-lang');
    const theme = useTheme();
    const title = 'Start your next task with AllServices CY - a huge collection of services provided by individuals and companies in Cyprus.';
    // const shareUrl = `https://allservices.cy/`;
    const shareUrl = window.location.href;
    const shareUrlImage = 'https://www.allservices.cy/logo.png';
    let firstItem = true;

    window.addEventListener('lang_changed', () => {
        lang = localStorage.getItem('allserv-lang');
        console.log('FooterCard() lang_changed, lang:', lang);
        getSanityData();
    });

    const getSanityData = () => {
        sanityClient
          .fetch(

            // `*[_type == "post" && categories[]._ref == "f721b49f-4572-4291-801e-540e17e8a8e5"] | order(_createdAt desc) {
            `*[_type == "post" && categories[]._ref == "f721b49f-4572-4291-801e-540e17e8a8e5"] | order(sortOrder asc) {
                title,
                titleRu,
                titleEl,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                body,
                bodyRu,
                bodyEl,
                categories
              }`
          )
          .then((data) => {
              // console.log('FooterCard() sanityClient.fetch data:', data);
              setRows(data);
          })
          .catch(console.error);

    }

    useEffect(() => {
        getSanityData();

          //social buttons
          // const script = document.createElement('script');
          // script.src = "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-63876ead5ce87795";
          // script.async = true;
          // document.body.appendChild(script);

    }, []);

    const singleArticle = (row, index) => {
        let title = '';
        // let body = '';
        switch (lang) {
            case 'ru':
                title = row?.titleRu? row.titleRu: row.title;
                // body = row?.bodyRu? row.bodyRu: row.body;
                break;
            case 'el':
                title = row?.titleEl? row.titleEl: row.title;
                // body = row?.bodyEl? row.bodyEl: row.body;
                break;
            default:
                title = row?.title;
                // body = row?.body;
        }
        // console.log('SingleArticle() row', row);
        let splitter = null;
        if (!firstItem) splitter = <span style={{ paddingLeft: '10px', paddingRight: '10px' }}> | </span>;
        firstItem = false;

        return (
        <Fragment key={index}>
            {splitter}
            <span>
                <Link onClick={() => {navigate(`/article/${row.slug.current}`)}} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} >
                    {title} 
                </Link>
            </span>
        </Fragment>
        )
    }

    //marginLeft: '24px', marginTop: '16px'
    sx.marginLeft = '24px';
    // sx.marginTop = '16px';
    if(!sx.marginTop) sx.marginTop = '16px';

    return (
        <Grid item xs={12}  sx={sx} id="footer" >
            <Grid container spacing={gridSpacing} sx={{ background: theme.palette.primary.light, paddingTop: '0px', paddingBottom: '0px', paddingLeft: window.innerWidth>600? '16px': 'inherit', paddingRight: window.innerWidth>600? '24px': 'inherit', borderRadius: (window.innerWidth>600)? '12px 12px 0px 0px': null }}>
                <Grid item className= "footer__articles" xs={12} sx={{ paddingBottom: 1 }}>
                    {rows.map((row, index) => {
                        return singleArticle(row, index);
                    }
                    )}
                </Grid>
                <Grid container className= "footer__social" spacing={gridSpacing} sx={{ paddingTop: 0, paddingBottom: '0px', paddingLeft: '16px', paddingRight: window.innerWidth>600? '24px': 'inherit', marginTop: 0 }}>
                    <Grid item xs={12} md={6}>
                        {/* <div className="addthis_inline_share_toolbox"></div> */}
                        <div className="social__container" style={{ display: 'flex', width: 'fit-content' }}>
                            {/* <div className="social__some-network">
                                <Typography variant='body2' sx={{ maxWidth: '80px', fontSize: '10px', display: 'inline-block' }}>
                                    Поделиться:
                                </Typography>
                            </div> */}
                            <div className="social__some-network">
                                <VKShareButton
                                url={shareUrl}
                                title={title}
                                image={shareUrlImage}
                                className="social__some-network__share-button"
                                >
                                <VKIcon size={32} round />
                                </VKShareButton>
                            </div>
                            <div className="social__some-network">
                                <OKShareButton
                                url={shareUrl}
                                image={shareUrlImage}
                                className="social__some-network__share-button"
                                >
                                <OKIcon size={32} round />
                                </OKShareButton>
                            </div>
                            <div className="social__some-network">
                                <MailruShareButton
                                url={shareUrl}
                                title={title}
                                className="social__some-network__share-button"
                                >
                                <MailruIcon size={32} round />
                                </MailruShareButton>
                            </div>
                            <div className="social__some-network">
                                <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                className="social__some-network__share-button"
                                >
                                <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>
                            <div className="social__some-network">
                                <WhatsappShareButton
                                url={shareUrl}
                                title={title}
                                separator=":: "
                                className="social__some-network__share-button"
                                >
                                <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <div className="social__some-network">
                                <TelegramShareButton
                                url={shareUrl}
                                title={title}
                                className="social__some-network__share-button"
                                >
                                <TelegramIcon size={32} round />
                                </TelegramShareButton>
                            </div>
                            <div className="social__some-network">
                                <ViberShareButton
                                url={shareUrl}
                                title={title}
                                className="social__some-network__share-button"
                                >
                                <ViberIcon size={32} round />
                                </ViberShareButton>
                            </div>
                            <div className="social__some-network">
                                <FacebookMessengerShareButton
                                url={shareUrl}
                                appId="521270401588372"
                                className="social__some-network__share-button"
                                >
                                <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                            </div>
                            <div className="social__some-network">
                                <EmailShareButton
                                url={shareUrl}
                                subject={title}
                                body="body"
                                className="social__some-network__share-button"
                                >
                                <EmailIcon size={32} round />
                                </EmailShareButton>
                            </div>

                            <div className="social__some-network">
                                <InstapaperShareButton
                                url={shareUrl}
                                title={title}
                                // description
                                body="body"
                                className="social__some-network__share-button"
                                >
                                <InstapaperIcon size={32} round />
                                </InstapaperShareButton>
                            </div>
                            <div className="social__some-network">
                                <LinkedinShareButton
                                url={shareUrl}
                                title={title}
                                // summary
                                // source
                                body="body"
                                className="social__some-network__share-button"
                                >
                                <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ height: 66 }}>
                        <Link href="https://play.google.com/store/apps/details?id=cy.allservices.mobapp" target="_blank">
                            <img src={googlePlay} alt="All Services CY Android app" style={{ height: '100%', paddingRight: 4 }}/>
                        </Link>
                        <Link href="https://apple.co/40JxE0m" target="_blank">
                            <img src={appStore} alt="All Services CY iOS app" style={{ height: '100%' }}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item className= "footer__copyrights" xs={12} sx={{ paddingRight: '24px'}}>
                    <Stack direction="row" justifyContent="space-between" sx={{ padding: '0px', border: 'none', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Typography variant="subtitle2" component={Link} href="/" target="_blank" underline="hover">
                            allservices.cy
                        </Typography>
                        <Typography variant="subtitle2" component={Link} href="https://unitex.cy" target="_blank" underline="hover">
                            Developed by &copy; unitex.cy
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Grid>
    );
});

FooterCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

FooterCard.defaultProps = {
    content: true
};

export default FooterCard;
