import { useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from '../Header';
import Sidebar from './Sidebar';

import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import Footer from '../Footer';
import BottomNavigation from '../BottomNavigation';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '0px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '0px',
        //     marginRight: '0px'
        // }
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '0px',
            marginRight: '0px'
         }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
// ==============================|| FRONT LAYOUT ||============================== //

const FrontLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const [isWebView,setIsWebView] = useState(false);
    const [footerStyle, setFooterStyle] = useState({});

    // Handle left drawer
    // const leftDrawerOpened = useSelector((state) => {
    //     console.log('FrontLayout() state.customization:', state.customization);
    //     return state.customization.opened;
    // });
    const leftDrawerOpened = false;
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
        navigate('/catalog/tickets');
    };

    useEffect(() => {
        // dispatch({ type: SET_MENU, opened: !matchDownMd });
        dispatch({ type: SET_MENU, opened: false });

        if(window.isWebView) setIsWebView(true);

        //next stuff is to place footer to the bottom of the page if not enough content on the page
        setTimeout(() => {
            const elem = document.getElementById('footer');
            if(elem) {
                // console.log('elem.style:', elem.style);
                const offsetTop = getOffset(elem).top;
                const elemHeight = elem.offsetHeight;
                // console.log('MainLayout() getOffset(footer).top:', offsetTop);
                // console.log('MainLayout() footer.offsetHeight:', elemHeight);
                // console.log('MainLayout() window.innerHeight:', window.innerHeight);
                if( (offsetTop+elemHeight) < window.innerHeight ) {
                    const addOffset = window.innerHeight - offsetTop - elemHeight + 16;
                    console.log('addOffset:', addOffset);
                    setFooterStyle({ marginTop: `${addOffset}px` });
                } else setFooterStyle({});
            } else console.log('MainLayout() footer element not found after timeout, why it renders so slowly?');
        }, 2000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }

    return (
        <Box sx={{ display: 'flex', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    // transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} >
                <Outlet />
                {isWebView? <BottomNavigation />: <Footer sx={footerStyle} />}
                
                
            </Main>
        </Box>
)};

export default FrontLayout;
