import { useState } from 'react';

import { Link } from '@mui/material';

import i18next from "i18next";

const languages = [
    { value: 'en', text: "EN" },
    { value: 'ru', text: "RU" },
    { value: 'el', text: "EL" }
]

// ==============================|| SEARCH INPUT ||============================== //

const LangSelectSection = () => {
    const sessionLang = localStorage.getItem('allserv-lang') || 'en';
    const [lang, setLang] = useState(sessionLang);
    const handleLangChange = val => { 
        if (lang !== val) {
            setLang(val);
            // let loc = "/";
            // window.location.replace(loc + "?lng=" + e.target.value);
            localStorage.setItem('allserv-lang',val);
            i18next.changeLanguage(val);
            window.dispatchEvent(new Event("lang_changed"));
        }
    }

    return (
        <>
                {languages.map(item => {
                    return (<Link sx={{ cursor: 'pointer', paddingRight: '24px' }} onClick={(event) => {handleLangChange(item.value)}}>{`${item.text} `}</Link>);
                })}

        </>
    );
};

export default LangSelectSection;
