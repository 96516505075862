import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { db } from 'utils/firebase-functions';
import { ref, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import NotificationSound from "assets/sounds/mixkit-positive-notification-951.mp3";
import { updateChatMessagesSetRead, updateChatNotReadCount } from 'utils/firebase-functions';
import { useTranslation } from 'react-i18next'

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    // const [prevNotificationCount, setPrevNotificationCount] = useState('');
    const userID = sessionStorage.getItem('userID');
    const [rows,setRows] = useState([]);
    const [connected, setConnected] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation(); 

    useEffect(() => {

        // console.log('NotificationSection() useEffect() userID:', userID);
        if (userID) {
            const chatsRef = ref(db, 'chats/' + userID);
            try {
                onValue(chatsRef, (snapshot) => {
                    const data = snapshot.val();
                    // console.log('NotificationSection chatsRef data:', data);
                    if (data) {
                        let counter = 0;
                        Object.entries(data).forEach(([key, value]) => {
                            // console.log(`${key} ${value}`);
                            if (typeof value === 'object') {
                                // value['id'] = key;
                                // console.log(value);
                                if (value.notReadCount) counter += value.notReadCount;
                            }
                        });
                        // if (counter === 0) counter = '';
                        setNotificationCount(counter);
                        // console.log('NotificationSection() counter:', counter, 'prevNotificationCount:', prevNotificationCount);
                        // if (counter && (!prevNotificationCount || counter < prevNotificationCount)) {
                        //     const audio = new Audio(NotificationSound);
                        //     audio.play();
                        //     console.log('the audio is supposedly playing');                            
                        // }
                        // setPrevNotificationCount(counter);
                    }
                })
                // .catch((err) => {
                //     console.error("Exception in NotificationSection onValue():", err);
                //     alert('Exception in NotificationSection onValue():' + JSON.stringify(err));
                // })
            } catch(err) {
                console.error('Exception in NotificationSection onValue():', err);

                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    // const credential = promptForCredentials();
                    // reauthenticateWithCredential(user, credential)

                    // alert('Exception in NotificationSection, user not authorised, you will be redirected to login page');
                    // navigate(`/login`);
                    alert('Exception in NotificationSection, you have to log in again');
                }

                alert('Exception in NotificationSection: ' + JSON.stringify(err) + ' auth.currentUser ' + currentUser);
            }
        };

        if (userID) {
            const auth = getAuth();
            onAuthStateChanged(auth, function(user) {
              if (user) {
                // User is signed in.
                // console.log('NotificationSection() onAuthStateChanged() user is signed in');
              } else {
                //in case iser logged itself out - re-read its value from sessionStorage, it should be cleared 
                const userID = sessionStorage.getItem('userID');
                if (!userID) {
                    console.log('!!! NotificationSection() onAuthStateChanged() user is signed out');
                    // alert('Firebase auth is lost, you have to log in again');
                }
                // let provider = new firebase.auth.GoogleAuthProvider();
                // firebase.auth().signInWithPopup(provider);
              }
            });
        };

        const connectedRef = ref(db, ".info/connected");
        onValue(connectedRef, (snap) => {
          if (snap.val() === true) {
            // console.log("connected");
            setConnected(true);
          } else {
            // console.log("not connected");
            setConnected(false);
          }
        });        

    }, [userID]);

    const handleToggle = () => {
        // console.log('NotificationSection() prevOpen.current:',prevOpen.current);
        if (!prevOpen.current) {
            // console.log('NotificationSection() getting new messages');
            const chatsRef = ref(db, 'chats/' + userID);
            onValue(chatsRef, (snapshot) => {
                const data = snapshot.val();
                // console.log('chatsRef data:', data);
                if (data) {
                    const newRows = [];
                    Object.entries(data).forEach(([key, value]) => {
                        // console.log(`${key} ${value}`);
                        if (typeof value === 'object') {
                            value['id'] = key;
                            if (!value.title) {
                                // console.log('broken chat item:', value, 'skipped');
                            } else {
                                if (value.notReadCount) newRows.push(value);
                            }
                        }
                    });
                    newRows.sort((a, b) => b.timestamp - a.timestamp);
                    setRows(newRows);
                    console.log('NotificationSection() newRows:', newRows);
                }
            });

        }
        setOpen((prevOpen) => !prevOpen);
        //navigate(`/dashboard/messages`);


    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            //anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const setAllRead = () => {
        for (let i=0; i < rows.length; i += 1) {
            updateChatMessagesSetRead(rows[i].id);
            updateChatNotReadCount(rows[i].id, userID, 0);
        }
    }

    const removeEventHandler = (id) => {
        // and this is to remove rows without messages
        let foundCounter = 0;
        for( var i = 0; i < rows.length; i++){ 
            if (rows[i].id === id) { 
                console.log('removeEventHandler() row to remove:', rows[i]);
                updateChatMessagesSetRead(rows[i].id);
                updateChatNotReadCount(rows[i].id, userID, 0);
                rows.splice(i, 1); 
                i--;
                foundCounter ++;
            }
        }
        setNotificationCount(notificationCount - foundCounter);
    }

    return (
        <>
        {userID &&
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
            <Badge badgeContent={'X'} color="error"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                invisible={connected}
            >
                <Badge badgeContent={notificationCount} color="primary">
                    <ButtonBase sx={{ borderRadius: '12px' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <IconBell stroke={1.5} size="21" />
                        </Avatar> 
                    </ButtonBase>
                </Badge>
            </Badge>
            </Box>
        }
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">{t('notifications-title')}</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={rows.length}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Button size="small" disableElevation onClick={() => {setOpen(false); setAllRead();}} sx={{ fontSize: '0.75rem' }}>
                                                        {t('notifications-mark-all-read')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Grid container direction="column" spacing={2}>
                                                    {/* <Grid item xs={12}>
                                                        <Box sx={{ px: 2, pt: 0.25 }}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                fullWidth
                                                                value={value}
                                                                onChange={handleChange}
                                                                SelectProps={{
                                                                    native: true
                                                                }}
                                                            >
                                                                {status.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                        </Box>
                                                    </Grid> */}
                                                    <Grid item xs={12} p={0}>
                                                        <Divider sx={{ my: 0 }} />
                                                    </Grid>
                                                </Grid>
                                                <NotificationList rows={rows} setOpen={setOpen} removeEventHandler={removeEventHandler}/>
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation onClick={() => {setOpen(false); navigate(`/dashboard/timeline`);}}>
                                            {t('notifications-view-all')}
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
