// assets
import { IconMap2, IconActivity, IconNews, IconWorld, IconUsers, IconMessagePlus, IconTicket, IconQuestionMark, IconTruckDelivery, IconBrandTwitter, IconStar, IconSettings, IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconMap2,
    IconActivity,
    IconNews,
    IconWorld,
    IconUsers,
    IconMessagePlus,
    IconTicket,
    IconQuestionMark,
    IconTruckDelivery,
    IconBrandTwitter,
    IconStar,
    IconSettings,
    IconBriefcase
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const catalogs = {
    id: 'catalogs',
    title: 'Catalogs',
    caption: 'System data',
    type: 'group',
    children: [
        {
            id: 'location',
            title: 'Location',
            type: 'collapse',
            icon: icons.IconMap2,

            children: [
                {
                    id: 'countries',
                    title: 'Countries',
                    type: 'item',
                    url: '/catalog/countries'
                    // target: true
                },
                {
                    id: 'cities',
                    title: 'Cities',
                    type: 'item',
                    url: '/catalog/cities'
                    // target: true
                },
                {
                    id: 'areas',
                    title: 'Areas',
                    type: 'item',
                    url: '/catalog/areas'
                    // target: true
                }
            ]
        },
        {
            id: 'servces_menu',
            title: 'Services',
            type: 'collapse',
            icon: icons.IconActivity,

            children: [
                {
                    id: 'service_categories',
                    title: 'Service categories',
                    type: 'item',
                    url: '/catalog/service-categories'
                    // target: true
                },
                {
                    id: 'services',
                    title: 'Services',
                    type: 'item',
                    url: '/catalog/services'
                    // target: true
                }
            ]
        },
        {
            id: 'blog_post_types',
            title: 'Blog post types',
            type: 'item',
            icon: icons.IconNews,
            url: '/catalog/blog-post-types'
            // target: true
        },
        {
            id: 'languages',
            title: 'Languages',
            type: 'item',
            icon: icons.IconWorld,
            url: '/catalog/languages'
            // target: true
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            icon: icons.IconUsers,
            url: '/catalog/users'
            // target: true
        },
        {
            id: 'become-contractor-requests',
            title: 'Become Contractor Requests',
            type: 'item',
            icon: icons.IconMessagePlus,
            url: '/catalog/become-contractor-requests'
            // target: true
        },
        {
            id: 'tickets',
            title: 'Tickets',
            type: 'item',
            icon: icons.IconTicket,
            url: '/catalog/tickets'
            // target: true
        },
        {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            url: '/dashboard/requests',
            icon: icons.IconQuestionMark,
            breadcrumbs: false
        },
        {
            id: 'offers',
            title: 'Offers',
            type: 'item',
            url: '/dashboard/offers',
            icon: icons.IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'orders-admin',
            title: 'Orders',
            type: 'item',
            url: '/dashboard/orders',
            icon: icons.IconTruckDelivery,
            breadcrumbs: false
        },
        {
            id: 'blog-posts',
            title: 'Blog Posts',
            type: 'item',
            url: '/dashboard/blog-posts',
            icon: icons.IconBrandTwitter,
            breadcrumbs: false
        },
        {
            id: 'reviews',
            title: 'Reviews',
            type: 'item',
            url: '/dashboard/reviews',
            icon: icons.IconStar,
            breadcrumbs: false
        },
        {
            id: 'global-settings',
            title: 'Global settings',
            type: 'item',
            url: '/catalog/global-settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default catalogs;
