// import dashboard from './dashboard';
// import pages from './pages';
import utilities from './utilities';
// import other from './other';
import catalogs from './catalogs';
// import contractor from './contractor';
// import customer from './customer';

// ==============================|| MENU ITEMS ||============================== //
const permissionsString = sessionStorage.getItem('permissions') || '0';
function strcmp(a, b) {
    // a = a.toString(), b = b.toString();
    let i;
    const n = Math.max(a.length, b.length);
    for (i = 0; i < n && a.charAt(i) === b.charAt(i); i += 1);
    if (i === n) return 0;
    return a.charAt(i) > b.charAt(i) ? -1 : 1;
}

// const menuItems = {
//     // items: [dashboard, pages, utilities, other]
//     items: [customer,contractor,catalogs,utilities]
// };
let myitems;
if (strcmp(permissionsString, '0') === 0) {
    // customer
    myitems = [];
} else if (strcmp(permissionsString, '2') === 0) {
    // contractor
    myitems = [];
} else if (strcmp(permissionsString, '5') === 0) {
    // administrator
    myitems = [catalogs,utilities];
} else {
    // client
    myitems = [utilities];
}
const menuItems = {
    items: myitems
};

export default menuItems;
