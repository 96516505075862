/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: '#d5e4e5',
            main: '#374548',
            dark: '#1c2422',
            200: '#bdcbcc',
            800: '#1c2422'
        },
        secondary: {
            light: '#d7e5d5',
            main: '#339e90',
            dark: '#257d6e',
            200: '#9edbd2',
            800: '#145449'
        },
        error: {
            light: '#ef9a9a',
            main: '#f44336',
            dark: '#c62828'
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: '#fff8e1',
            main: '#ffe57f',
            dark: '#ffc107'
        },
        success: {
            light: '#b9f6ca',
            200: '#69f0ae',
            main: '#00e676',
            dark: '#00c853'
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault
        }
    };
}
