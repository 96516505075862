import * as React from 'react';
import { Badge, Box, Paper,  } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { IconChevronLeft, IconQuestionMark, IconTruckDelivery, IconBell, IconBriefcase, IconListSearch } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from 'utils/firebase-functions';
import { ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  // let permissionsString = sessionStorage.getItem('permissions');
  const permissionsString = useSelector((state) => state.session.permissions).toString();
  console.log('SimpleBottomNavigation() permissionsString from redux storage:', permissionsString);


  const token = sessionStorage.getItem('mytoken');
  //alert('SimpleBottomNavigation() token:', token);
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const { t } = useTranslation(); 

    useEffect(() => {

        // console.log('SimpleBottomNavigation() useEffect() userID:', userID);
        setTimeout(() => {
          // permissionsString = sessionStorage.getItem('permissions') || '0';
          const userID = sessionStorage.getItem('userID');
          if (userID) {
              const chatsRef = ref(db, 'chats/' + userID);
              try {
                  onValue(chatsRef, (snapshot) => {
                      const data = snapshot.val();
                      // console.log('NotificationSection chatsRef data:', data);
                      if (data) {
                          let counter = 0;
                          Object.entries(data).forEach(([key, value]) => {
                              // console.log(`${key} ${value}`);
                              if (typeof value === 'object') {
                                  // value['id'] = key;
                                  // console.log(value);
                                  if (value.notReadCount) counter += value.notReadCount;
                              }
                          });
                          // if (counter === 0) counter = '';
                          setNotificationCount(counter);
                          // console.log('NotificationSection() counter:', counter, 'prevNotificationCount:', prevNotificationCount);
                          // if (counter && (!prevNotificationCount || counter < prevNotificationCount)) {
                          //     const audio = new Audio(NotificationSound);
                          //     audio.play();
                          //     console.log('the audio is supposedly playing');                            
                          // }
                          // setPrevNotificationCount(counter);
                      }
                  })
                  // .catch((err) => {
                  //     console.error("Exception in NotificationSection onValue():", err);
                  //     alert('Exception in NotificationSection onValue():' + JSON.stringify(err));
                  // })
              } catch(err) {
                  console.error('Exception in SimpleBottomNavigation onValue():', err);

                  const auth = getAuth();
                  const currentUser = auth.currentUser;
                  if (!currentUser) {
                      // const credential = promptForCredentials();
                      // reauthenticateWithCredential(user, credential)

                      // alert('Exception in NotificationSection, user not authorised, you will be redirected to login page');
                      // navigate(`/login`);
                      alert('Exception in SimpleBottomNavigation, you have to log in again');
                  }

                  alert('Exception in SimpleBottomNavigation: ' + JSON.stringify(err) + ' auth.currentUser ' + currentUser);
              }
          } else {
            // !userID - i.e. after logout
            setNotificationCount(0);
          }
        }, 200);

    }, []);

  function strcmp(a, b) {
        if (a) a = a.toString();
        else a = '';
        if (b) b = b.toString();
        else b = '';
      let i;
      const n = Math.max(a.length, b.length);
      for (i = 0; i < n && a.charAt(i) === b.charAt(i); i += 1);
      if (i === n) return 0;
      return a.charAt(i) > b.charAt(i) ? -1 : 1;
  }

  return (
    <>
    <Box sx={{ height: '30px' }}>
    </Box>
    <Box sx={{ }}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      {(strcmp(permissionsString, '0') === 0 || strcmp(permissionsString, '-1') === 0)?
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {/* <BottomNavigationAction label="Customer" /> */}
          <BottomNavigationAction label={t('bottomnav-back')} icon={<IconChevronLeft />} onClick={() => navigate(-1)} />
          <BottomNavigationAction label={t('bottomnav-my-requests')} icon={<IconQuestionMark />} onClick={() => navigate(token? `/dashboard/requests`: '/login')} />
          <BottomNavigationAction label={t('bottomnav-my-orders')} icon={<IconTruckDelivery />} onClick={() => navigate(token? `/dashboard/orders`: '/login')} />
          <BottomNavigationAction
            label={t('bottomnav-notifications')} 
            icon={
              <Badge badgeContent={notificationCount} color="primary">
                <IconBell />
              </Badge>
            } 
            onClick={() => navigate(token? `/dashboard/timeline`: '/login')} 
          />
              
      </BottomNavigation>: null
      }
      {(strcmp(permissionsString, '2') === 0)?
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {/* <BottomNavigationAction label="Contractor" /> */}
          <BottomNavigationAction sx={{ minWidth: '60px' }} label={t('bottomnav-back')} icon={<IconChevronLeft />} onClick={() => navigate(-1)} />
          <BottomNavigationAction sx={{ minWidth: '60px' }} label={t('bottomnav-search')} icon={<IconListSearch />} onClick={() => navigate(`/dashboard/request-search`)} />
          <BottomNavigationAction sx={{ minWidth: '60px' }} label={t('bottomnav-my-offers')} icon={<IconBriefcase />} onClick={() => navigate(`/dashboard/offers`)} />
          <BottomNavigationAction sx={{ minWidth: '60px' }} label={t('bottomnav-orders')} icon={<IconTruckDelivery />} onClick={() => navigate(`/dashboard/orders-contractor`)} />
          <BottomNavigationAction sx={{ minWidth: '60px' }}
            label={t('bottomnav-notifications')} 
            icon={
              <Badge badgeContent={notificationCount} color="primary">
                <IconBell />
              </Badge>
            } 
            onClick={() => navigate(`/dashboard/timeline`)} 
          />
        </BottomNavigation>: null
      }
      </Paper>
    </Box>
    </>
  );
}