const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '/',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    vivaPaymentURL: 'https://demo.vivapayments.com/web2',
    // apiHost: 'http://localhost:3001'
    apiHost: 'https://prowork-api.vercel.app'
};

export default config;
