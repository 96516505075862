// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Chip,
    Divider,
    Grid,
    Link,
    List,
    Typography
} from '@mui/material';

// assets
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import config from 'config';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ rows, setOpen, removeEventHandler }) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };
    const chipRequestSX = {
        ...chipSX,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        height: 28
    };
    // console.log('NotificationList() rows:',rows);
    // console.log('NotificationList() setOpen:',setOpen);
    const navigate = useNavigate();
    const permissions = sessionStorage.getItem('permissions');

    // Calculate the day diff
    const getDayDiff = timestamp => {
        let a = moment();
        let b = moment(timestamp);
        let diff = a.diff(b, 'year');
        if (diff === 0) {
            diff = a.diff(b, 'month');
            if (diff === 0) {
                diff = a.diff(b, 'days');
                if (diff === 0) {
                    diff = a.diff(b, 'hour');
                    if (diff === 0) {
                        diff = a.diff(b, 'minute');
                        if (diff === 0) {
                            diff = a.diff(b, 'second');
                            return `${diff} second(s) before`;
                        } else {
                            return `${diff} minute(s) before`;
                        }
                    } else {
                        return `${diff} hour(s) before`;
                    }
                } else {
                    return `${diff} days(s) before`;
                }
            } else {
                return `${diff} month(s) before`;
            }
        } else {
            return `${diff} year(s) before`;
        }
    };

    const NotificationItem = ({ row }) => {
        const [messageText, setMessageText] = useState(row.lastmessage);
        const { t } = useTranslation(); 
        const [translateLinkText, setTranslateLinkText] = useState(t('messageitem-translate'));
        function translateText() {
            setTranslateLinkText(t('messageitem-translating'));
            const apiHost = config.apiHost;
            return fetch(`${apiHost}/api/messenger`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'translate',
                    text: row.lastmessage,
                    targetLang: localStorage.getItem('allserv-lang') || 'en'
                })
            })
                .then((data) => data.json())
                .then((result) => {
                    // console.log('MessageItem() translateText() result', result);
                    // console.log('MessageItem() result.translation', result.translation);
                    if(result.status) {
                        setMessageText(result.translation);
                        setTranslateLinkText(t('messageitem-translated'));
                    }
                    return result;
                });
        }
        return (
                <>
                <ListItemWrapper >
                    <Grid container 
                        direction="column" 
                        onClick={() => {
                            console.log('NotificationList() onClick row:',row);
                            removeEventHandler(row.id);
                            if (row.requestID) navigate(`/dashboard/request?id=${row.requestID}`);
                            if (row.orderID) navigate(`/dashboard/order?id=${row.orderID}`);
                            if (row.ticketStatus !== undefined) {
                                if (row.ticketStatus !== '') {
                                    if(permissions < 5) {
                                        navigate(`/dashboard/ticket?id=${row.id}`);
                                    }
                                    else navigate(`/catalog/tickets`);
                                }
                            }
                            setOpen(false);
                        }}
                    >
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Typography variant="subtitle2">{getDayDiff(row.timestamp)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Typography variant="body">{messageText}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {/* <Grid item>
                                    <Chip label="Unread" sx={chipErrorSX} />
                                </Grid>
                                <Grid item>
                                    <Chip label="New" sx={chipWarningSX} />
                                </Grid> */}
                                {row.requestID &&
                                <Grid item>
                                    <Chip label={`Request ${row.requestNumber}`} sx={chipRequestSX} />
                                </Grid>
                                }
                                {row.orderID &&
                                <Grid item>
                                    <Chip label={`Order ${row.orderNumber}`} sx={chipSuccessSX} />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                        <Typography noWrap color="inherit" sx={{ marginLeft: 'auto', marginRight: '0px', fontSize: '0.6rem', paddingLeft: '48px', textAlign: 'right' }}>
                            <Link variant="link" key={row} onClick={() => { translateText() }} sx={{ paddingRight: '24px', textDecoration: 'underline', cursor: 'pointer' }}>{translateLinkText}</Link>
                        </Typography>
                </ListItemWrapper>
                <Divider />
                </>
        );        
    }

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }

            }}
        >
            {rows.map((row) => (
                <NotificationItem row={row} />
            ))}
        </List>
    );
};

export default NotificationList;
