import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import UpgradePlanCard from './UpgradePlanCard';
import BecomeContractorCard from './BecomeContractorCard';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings, IconQuestionMark, IconTruckDelivery, IconMessages, IconBriefcase, IconListSearch, IconBrandTwitter, IconStar, IconMenu2, IconWorld, IconTicket, IconNews } from '@tabler/icons';
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from 'react-redux';
import { SET_MENU } from 'store/actions';
import { useTranslation } from 'react-i18next'
import LangSelectSection from '../LangSelectMobSection';
import sanityClient from "sanityClient.js";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const auth = getAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation(); 
    const [footerArticles, setFooterArticles] = useState([]);
    const lang = localStorage.getItem('allserv-lang');
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        // console.log('Logout');
        signOut(auth)
        .then(() => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('mytoken');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('permissions');
            sessionStorage.removeItem('userID');
            sessionStorage.removeItem('companyID');
            sessionStorage.removeItem('photoURL');

            // and this is to make sure next time there will be auth window shown without autologin
            localStorage.removeItem('username');
            localStorage.removeItem('userID');
            localStorage.removeItem('token');
            localStorage.removeItem('photoURL');
            localStorage.removeItem('permissions');
            localStorage.removeItem('mytoken');

            dispatch({ type: SET_MENU, opened: false }); // to close left drawer
            // navigate(`/`);
            window.location.href='/'; // this is to reload app, bacuse login as user with different permissions might require reload data in some components/menu
        })
        .catch((error) => {
          // An error happened.
            console.log(error);
        });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;

        sanityClient
          .fetch(

            `*[_type == "post" && categories[]._ref == "f721b49f-4572-4291-801e-540e17e8a8e5"] | order(_createdAt desc) {
                title,
                titleRu,
                titleEl,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                body,
                bodyRu,
                bodyEl,
                categories,
                _id
              }`
          )
          .then((data) => {
              // console.log('FooterCard() sanityClient.fetch data:', data);
              setFooterArticles(data);
          })
          .catch(console.error);

    }, [open]);

    const permissions = sessionStorage.getItem('permissions');
    const userName = sessionStorage.getItem('username');
    const photoURL = sessionStorage.getItem('photoURL');
    let userRole = '';
    switch (permissions) {
        case '0':
            userRole = t('role-customer');
            break;
        case '2':
            userRole = t('role-contractor');
            break;
        case '5':
            userRole = t('role-administrator');
            break;
        default: 
            userRole = 'undefined';
    }

    const singleArticle = (row) => {
        // console.log('singleArticle() row._id:', row._id);
        let title = '';
        // let body = '';
        switch (lang) {
            case 'ru':
                title = row?.titleRu? row.titleRu: row.title;
                // body = row?.bodyRu? row.bodyRu: row.body;
                break;
            case 'el':
                title = row?.titleEl? row.titleEl: row.title;
                // body = row?.bodyEl? row.bodyEl: row.body;
                break;
            default:
                title = row?.title;
                // body = row?.body;
        }
        // console.log('SingleArticle() row', row);

        return (
                <ListItemButton
                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                    selected={selectedIndex === row._id}
                    onClick={(event) => handleListItemClick(event, row._id, `/article/${row.slug.current}`)}
                >
                    <ListItemIcon>
                        <IconNews stroke={1.5} size="21" />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">{title}</Typography>} />
                </ListItemButton>
        )
    }

    const aboutSection = () => {
        return (
            <>
                <Divider />
                <Typography variant="h3">{t('menu-about')}</Typography>
                    {footerArticles.map((row) => {
                        return singleArticle(row);
                    }
                    )}
            </>
        );
    }

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={photoURL ? photoURL : User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconMenu2 stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={(window.innerWidth>600)? anchorRef.current: null}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        },
                    ]
                }}
                style={{ zIndex: 10 }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">{t('menu-good-morning')}</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {userName}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">{userRole}</Typography>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: window.isWebView? 'calc(100vh - 50px)': 'calc(100vh - 150px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            {permissions === '0' && <BecomeContractorCard />}
                                            
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {(permissions === '2') &&
                                                <>
                                                    <Divider sx={{ paddingTop: '10px' }}/>
                                                    <Typography variant="h3">{t('menu-contractor-menu')}</Typography>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 10}
                                                        onClick={(event) => handleListItemClick(event, 10, '/dashboard/offers')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconBriefcase stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-my-offers')}</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 11}
                                                        onClick={(event) => handleListItemClick(event, 11, '/dashboard/orders-contractor')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconTruckDelivery stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-my-orders-contractor')}</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 12}
                                                        onClick={(event) => handleListItemClick(event, 12, '/dashboard/request-search')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconListSearch stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-request-search')}</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 13}
                                                        onClick={(event) => handleListItemClick(event, 13, '/dashboard/requests-contractor')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconQuestionMark stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-requests-contractor')}</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 14}
                                                        onClick={(event) => handleListItemClick(event, 14, '/dashboard/blog-posts')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconBrandTwitter stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-blog-posts')}</Typography>} />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 15}
                                                        onClick={(event) => handleListItemClick(event, 15, '/dashboard/reviews')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconStar stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-reviews')}</Typography>} />
                                                    </ListItemButton>
                                                </>
                                                }

                                                {(permissions < 5) &&
                                                <>
                                                    <Divider  sx={{ paddingTop: '10px' }}/>
                                                    <Typography variant="h3">{t('menu-customer-menu')}</Typography>
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/dashboard/requests')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconQuestionMark stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-my-requests')}</Typography>} />
                                                    </ListItemButton>
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 1}
                                                        onClick={(event) => handleListItemClick(event, 1, '/dashboard/orders')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconTruckDelivery stroke={1.5} size="21" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('menu-my-orders')}</Typography>} />
                                                    </ListItemButton>
                                                </>
                                                }

                                                <Divider  sx={{ paddingTop: '10px' }}/>
                                                <Typography variant="h3">{t('menu-other')}</Typography>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 2}
                                                    onClick={(event) => handleListItemClick(event, 2, '/dashboard/timeline')}
                                                >
                                                    <ListItemIcon>
                                                        <IconMessages stroke={1.5} size="21" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">{t('menu-timeline')}</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={(event) => handleListItemClick(event, 4, '/dashboard/tickets')}
                                                >
                                                    <ListItemIcon>
                                                        <IconTicket stroke={1.5} size="21" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">{t('menu-tickets')}</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 3}
                                                    onClick={(event) => handleListItemClick(event, 3, '/dashboard/account-settings')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="21" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">{t('menu-account-settings')}</Typography>} />
                                                </ListItemButton>
                                            {(window.innerWidth < 600) &&
                                                <ListItem
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    onClick={(event) => setOpen(false)}
                                                >
                                                    <ListItemIcon>
                                                        <IconWorld stroke={1.5} size="21" />
                                                    </ListItemIcon>
                                                    <LangSelectSection />
                                                </ListItem>
                                            }
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={() => {
                                                        setLogoutDialogOpen(true);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="21" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">{t('menu-logout')}</Typography>} />
                                                </ListItemButton>
                                                {window.isWebView? aboutSection(): null}
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
             <Dialog
                open={logoutDialogOpen}
                onClose={() => {handleLogout()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {t('logout-dialog-title')}
            </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('logout-dialog-text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setLogoutDialogOpen(false);
                            // navigate(`/`);
                            handleLogout();
                        }} 
                    autoFocus
                    >
                        {t('a-button-close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfileSection;
