//UPDATED FROM VERCEL LATEST DEPLOYMENT

import { getDatabase, ref, onValue, serverTimestamp, push, update } from "firebase/database";
import { initializeApp } from 'firebase/app';
import config from 'config';

export const firebaseConfig = {
    apiKey: "AIzaSyCQEbDdBgMMWt3E8MXr1q5jEEDRicC2jO0",
    authDomain: "prowork-messages.firebaseapp.com",
    databaseURL: "https://prowork-messages-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "prowork-messages",
    storageBucket: "prowork-messages.appspot.com",
    messagingSenderId: "1009951909180",
    appId: "1:1009951909180:web:204da1337668caff426a39"
    // measurementId: "G-TR7FJEW7G1"
};

initializeApp(firebaseConfig);
export const db = getDatabase();
// export const ref;

export const getRef = (refPath) => {
	return ref(db, refPath);
}

// export const userID = 2;
// export const userName = 'Второй пользователь';

export const getChatTitle = (chatKey, recipientID) => {
    return new Promise(function(resolve, reject) {
        console.log('getChatTitle started for chat ', chatKey);
        let foundChatTitle = '';
        const chatPath = 'chats/' + recipientID + '/' +chatKey;
        console.log('getChatTitle() chatPath:', chatPath);
        const chatRef = ref(db, chatPath);
        onValue(chatRef, (snapshot) => {
            const data = snapshot.val();
            console.log('getChatTitle() chat data:', data);
            // return;
            if (data) {
                foundChatTitle = data.title;
                console.log('foundChatTitle:', foundChatTitle);
                // return foundChatTitle;
                resolve(foundChatTitle);
            // } else resolve(`Title not found for chat id ${chatKey}`);
            } else reject(`Title not found for chat id ${chatKey}`);
        });
    });
}

export const getChatData = (chatKey, recipientID) => {
    return new Promise(function(resolve, reject) {
        console.log('getChatData started for chat ', chatKey);
        // let foundChatTitle = '';
        const chatPath = 'chats/' + recipientID + '/' +chatKey;
        console.log('getChatData() chatPath:', chatPath);
        const chatRef = ref(db, chatPath);
        onValue(chatRef, (snapshot) => {
            const data = snapshot.val();
            console.log('getChatData() chat data:', data);
            // return;
            if (data) {
                resolve(data);
            // } else resolve(`Title not found for chat id ${chatKey}`);
            } else reject(`Could not get data for chat id ${chatKey}`);
        });
    });
}

export const sendMessageM = (params) => {
    console.log('sendMessageM() started, params:', params);
    return new Promise(function(resolve, reject) {
        // foundChatID, chatTitle, senderID, senderName, recipientID, messageText, requestID = null, reviewID

        if (!params.chatID) {
            console.error('sendMessageM() no chatID');
            reject('sendMessageM() no chatID provided');
            return;
        };
        if (!params.chatTitle) {
            console.error('sendMessageM() no chatTitle');
            reject('sendMessageM() no chatTitle provided');
            return;
        };
        if (!params.senderID || params.senderID === undefined) {
            console.error('sendMessageM() no senderID');
            reject('sendMessageM() no senderID provided');
            return;
        };
        if (!params.senderName) {
            console.error('sendMessageM() no senderName');
            reject('sendMessageM() no senderName provided');
            return;
        };
        if (!params.recipientID) { // fresh tickets won't have recipient/admin assigned, we dill with this later
        // if (!params.recipientID && (params.ticketStatus === 'undefined')) { // fresh tickets won't have recipient/admin assigned, we dill with this later
            console.error('sendMessageM() no recipientID');
            reject('sendMessageM() no recipientID provided');
            return;
        };
        if (!params.messageText) {
            console.error('sendMessageM() no messageText');
            reject('sendMessageM() no messageText provided');
            return;
        };


        // //temporary
        // if(params.recipientID === 'admin') params.recipientID = '9UGATIrsPQMwugmyN6OMPVfaLvw2';

            const userName = sessionStorage.getItem('username');
            const permissions = sessionStorage.getItem('permissions');
            const token = sessionStorage.getItem('mytoken');
            const userID = sessionStorage.getItem('userID');

            const apiHost = config.apiHost;
            fetch(`${apiHost}/api/firebase-messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'send-message',
                    data: params,
                    userData: {
                        userName,
                        userID,
                        userPermissions: permissions,
                        userToken: token
                    }
                })
            })
                .then((data) => data.json())
                .then((result) => {
                    console.log('sendMessageM() result', result);
                    if(result.status) resolve(result);
                    else reject(result.description);
                })
                .catch((e) => {
                    reject(e);
                })

        //temporary
        if(params.recipientID === 'admin') {
            //we will send notification to the selected user
            params.recipientID = '9UGATIrsPQMwugmyN6OMPVfaLvw2';   

            fetch(`${apiHost}/api/firebase-messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mode: 'send-message',
                    data: params,
                    userData: {
                        userName,
                        userID,
                        userPermissions: permissions,
                        userToken: token
                    }
                })
            })
                .then((data) => data.json())
                .then((result) => {
                    console.log('sendMessageM() result', result);
                    if(result.status) resolve(result);
                    else reject(result.description);
                })
                .catch((e) => {
                    reject(e);
                })
        }

    });
}

export const updateAppBadgeMessageCount = () => {
    return new Promise(function(resolve, reject) {
        const apiHost = config.apiHost;
        const userName = sessionStorage.getItem('username');
        const permissions = sessionStorage.getItem('permissions');
        const token = sessionStorage.getItem('mytoken');
        const userID = sessionStorage.getItem('userID');
        fetch(`${apiHost}/api/firebase-messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mode: 'update-app-badge',
                userData: {
                    userName,
                    userID,
                    userPermissions: permissions,
                    userToken: token
                }
            })
        })
            .then((data) => data.json())
            .then((result) => {
                console.log('updateAppBadgeMessageCount() result', result);
                resolve(result);
            })
            .catch((e) => {
                reject(e);
            })
    });
}

export const updateChatNotReadCount = (chatID, userID, number) => {
    if (!chatID) {
        console.log('updateChatNotReadCount() no chatID provided!!!');
        return;
    }
    if (!userID) {
        console.log('updateChatNotReadCount() no userID provided!!!');
        return;
    }
    const updates = {};

    // Write the new message's data simultaneously in the posts list and the user's post list.
    const myrefpath = 'chats/' + userID + '/' + chatID + '/notReadCount';
    updates[myrefpath] = number;
    // console.log('updates:', updates);

    update(ref(db), updates)
    .then(() => {
        return updateAppBadgeMessageCount();
    })
    .then(() => {
      // Data saved successfully!
      console.log('data updated for', myrefpath);
    })
    .catch((error) => {
      // The write failed...
        console.log('update error:', error);
    });
}

export const updateChatMessagesSetRead = (chatID) => {
    if (chatID) {
        const messagesRef = ref(db, 'messages/' + chatID);
        const updates = {};
        onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            // console.log('messagesRef data:', data);
            if (data) {
                Object.entries(data).forEach(([key, value]) => {
                    // console.log(`${key} ${value}`);
                    if (typeof value === 'object') {
                        value['id'] = key;
                        updates['messages/' + chatID + '/' + key + '/read'] = true;
                    }
                });
            }
        });
        setTimeout(() => {
            // console.log('updateChatMessagesSetRead updates:', updates);
            update(ref(db), updates)
            .then(() => {
              // Data saved successfully!
              console.log('data updated for read messages');
            })
            .catch((error) => {
              // The write failed...
                console.log('update error:', error);
            });
        }, 500);
    }


}

export const createChat = (chatTitle, senderID, recipientID, messageText) => {
    return new Promise(function(resolve, reject) {
        const updates = {};
        let newChatKey = '';
        let newChat = {};
        if (!messageText) messageText = 'Here you can send message to the Customer or Contractor';
        const senderName = 'Admin';
        const newMessage = {
            senderID: 0,
            senderName,
            messageText,
            // datetime: new Date(),
            timestamp: serverTimestamp()
        }

        newChatKey = push(ref(db), 'chats/' + recipientID).key;
        const newChatMembers = {
            [recipientID]: true,
            [senderID]: true,
            // [userID]: true
        }
        updates['members/' + newChatKey] = newChatMembers;
        // this is to update chat's last message and update time for easy listing
        newChat = {
            title: chatTitle,
            lastmessage: `${senderName}: ${messageText}`,
            timestamp: serverTimestamp()
        }

        // Get a key for a new Message.
        const newMessageKey = push(ref(db), 'messages/' + recipientID).key;

        // Write the new message's data simultaneously in the posts list and the user's post list.
        updates['messages/' + newChatKey + '/' + newMessageKey] = newMessage;
        updates['chats/' + senderID + '/' + newChatKey] = newChat;
        // and this is to set notReadCount for the recipient's chat
        // const newChat2 = Object.assign({}, newChat);
        // newChat2['notReadCount'] = 1;
        // updates['chats/' + recipientID + '/' + newChatKey] = newChat2;
        updates['chats/' + recipientID + '/' + newChatKey] = newChat;
        console.log('createChat() updates:', updates);

        update(ref(db), updates)
        .then(() => {
          // Data saved successfully!
          resolve(newChatKey);
        })
        .catch((error) => {
          // The write failed...
            console.log('update error:', error);
            reject(error);
        });
    });
}

// export const createChatM = (chatTitle, senderID, recipientID, messageText) => {
export const createChatM = (params) => {
    return new Promise(function(resolve, reject) {
        const newChatKey = push(ref(db), 'chats/' + params.recipientID).key;
        params['chatID'] = newChatKey;
        sendMessageM(params)
        .then((result) => {
            console.log('createChatM() after sendMessaageM() result:', result);
            if (result.status) {
                resolve({
                    status: true,
                    chatID: newChatKey
                });
            } else {
                reject('createChatM() something went wrong in sendMessaageM()');
            }
            // resolve(result);
        })
        .catch((err) => {
            console.log('createChatM() error:', err);
            reject(err);
        });


        // const updates = {};
        // let newChatKey = '';
        // let newChat = {};
        // if (!params.chatTitle) {
        //     reject('createChatM() no chatTitle');
        //     return;
        // };
        // if (!params.senderID) {
        //     reject('createChatM() no senderID');
        //     return;
        // };
        // if (!params.senderName) {
        //     reject('createChatM() no senderName');
        //     return;
        // };
        // if (!params.recipientID) {
        //     reject('createChatM() no recipientID');
        //     return;
        // };
        // let messageText = params.messageText;
        // if (!messageText) messageText = 'Here you can send message to the Customer or Contractor';
        // // const senderName = 'Admin';
        // const newMessage = {
        //     senderID: 0,
        //     senderName,
        //     messageText,
        //     // datetime: new Date(),
        //     timestamp: serverTimestamp()
        // }

        // newChatKey = push(ref(db), 'chats/' + params.recipientID).key;
        // const newChatMembers = {
        //     [params.recipientID]: true,
        //     [params.senderID]: true,
        //     // [userID]: true
        // }
        // updates['members/' + newChatKey] = newChatMembers;
        // // this is to update chat's last message and update time for easy listing
        // newChat = {
        //     title: params.chatTitle,
        //     lastmessage: `${senderName}: ${messageText}`,
        //     timestamp: serverTimestamp(),

        // }

        // // Get a key for a new Message.
        // const newMessageKey = push(ref(db), 'messages/' + params.recipientID).key;

        // // Write the new message's data simultaneously in the posts list and the user's post list.
        // updates['messages/' + newChatKey + '/' + newMessageKey] = newMessage;
        // updates['chats/' + params.senderID + '/' + newChatKey] = newChat;
        // // and this is to set notReadCount for the recipient's chat
        // // const newChat2 = Object.assign({}, newChat);
        // // newChat2['notReadCount'] = 1;
        // // updates['chats/' + recipientID + '/' + newChatKey] = newChat2;
        // updates['chats/' + params.recipientID + '/' + newChatKey] = newChat;
        // console.log('createChat() updates:', updates);

        // update(ref(db), updates)
        // .then(() => {
        //   // Data saved successfully!
        //   resolve(newChatKey);
        // })
        // .catch((error) => {
        //   // The write failed...
        //     console.log('update error:', error);
        //     reject(error);
        // });
    });
}

export const createChatTicket = (chatTitle, senderID, recipientID) => {
    return new Promise(function(resolve, reject) {

        //temporary
        if(recipientID === 'admin') recipientID = '9UGATIrsPQMwugmyN6OMPVfaLvw2';

        const updates = {};
        let newChatKey = '';
        let newChat = {};
        // const messageText = 'here you can send message to the customer or contractor';
        // const newMessage = {
        //     senderID: 0,
        //     senderName,
        //     messageText,
        //     // datetime: new Date(),
        //     timestamp: serverTimestamp()
        // }

        newChatKey = push(ref(db), 'chats/' + recipientID).key;
        const newChatMembers = {
            [recipientID]: true,
            [senderID]: true,
            // [userID]: true
        }
        updates['members/' + newChatKey] = newChatMembers;
        // this is to update chat's last message and update time for easy listing
        newChat = {
            title: chatTitle,
            // lastmessage: `${senderName}: ${messageText}`,
            timestamp: serverTimestamp()
        }

        // Get a key for a new Message.
        // const newMessageKey = push(ref(db), 'messages/' + recipientID).key;

        // Write the new message's data simultaneously in the posts list and the user's post list.
        // updates['messages/' + newChatKey + '/' + newMessageKey] = newMessage;
        updates['chats/' + senderID + '/' + newChatKey] = newChat;
        // and this is to set notReadCount for the recipient's chat
        // const newChat2 = Object.assign({}, newChat);
        // newChat2['notReadCount'] = 1;
        // updates['chats/' + recipientID + '/' + newChatKey] = newChat2;
        updates['chats/' + recipientID + '/' + newChatKey] = newChat;
        console.log('createChatTicket() updates:', updates);

        update(ref(db), updates)
        .then(() => {
          // Data saved successfully!
          resolve(newChatKey);
        })
        .catch((error) => {
          // The write failed...
            console.log('update error:', error);
            reject(error);
        });
    });
}