// project imports
// import config from 'config';

// action - state management
import * as actionTypes from './actions2';

export const initialState = {
    // isOpen: [], // for active default menu
    // borderRadius: config.borderRadius,
    // opened: true
    permissions: -1,
    selectedCategory: '',
    selectedCategoryRow: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions
            };
        case actionTypes.SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.selectedCategory
            };
        case actionTypes.SELECTED_CATEGORY_ROW:
            return {
                ...state,
                selectedCategoryRow: action.selectedCategoryRow
            };
        default:
            return state;
    }
};

export default sessionReducer;
