import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const Countries = Loadable(lazy(() => import('views/dashboard/Countries')));
const Cities = Loadable(lazy(() => import('views/dashboard/Cities')));
const Areas = Loadable(lazy(() => import('views/dashboard/Areas')));
const ServiceCategories = Loadable(lazy(() => import('views/dashboard/ServiceCategories')));
const Services = Loadable(lazy(() => import('views/dashboard/Services')));
const BlogPostTypes = Loadable(lazy(() => import('views/dashboard/BlogPostTypes')));
const Languages = Loadable(lazy(() => import('views/dashboard/Languages')));
const Offers = Loadable(lazy(() => import('views/dashboard/Offers')));
const Offer = Loadable(lazy(() => import('views/dashboard/Offer')));
const Requests = Loadable(lazy(() => import('views/dashboard/Requests')));
const RequestsContractor = Loadable(lazy(() => import('views/dashboard/RequestsContractor')));
const Request = Loadable(lazy(() => import('views/dashboard/Request')));
const Messages = Loadable(lazy(() => import('views/dashboard/Chat')));
const Timeline = Loadable(lazy(() => import('views/dashboard/Timeline')));
const Orders = Loadable(lazy(() => import('views/dashboard/Orders')));
const Order = Loadable(lazy(() => import('views/dashboard/Order')));
const Users = Loadable(lazy(() => import('views/dashboard/Users')));
const BecomeContractorRequest = Loadable(lazy(() => import('views/dashboard/BecomeContractorRequest')));
const BecomeContractorRequests = Loadable(lazy(() => import('views/dashboard/BecomeContractorRequests')));
const UserAccountSettings = Loadable(lazy(() => import('views/dashboard/UserAccountSettings')));
const RequestSearch = Loadable(lazy(() => import('views/dashboard/RequestSearch')));
const OrdersContractor = Loadable(lazy(() => import('views/dashboard/OrdersContractor')));
const BlogPosts = Loadable(lazy(() => import('views/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('views/dashboard/BlogPost')));
const Reviews = Loadable(lazy(() => import('views/dashboard/Reviews')));
const Review = Loadable(lazy(() => import('views/dashboard/Review')));
const Tickets = Loadable(lazy(() => import('views/dashboard/Tickets')));
const GlobalSettings = Loadable(lazy(() => import('views/dashboard/GlobalSettings')));
const PaymentSuccess = Loadable(lazy(() => import('views/pages/payment-success')));
const PaymentFailure = Loadable(lazy(() => import('views/pages/payment-failure')));
const TicketsUser = Loadable(lazy(() => import('views/dashboard/TicketsUser')));
const Ticket = Loadable(lazy(() => import('views/dashboard/Ticket')));
const UserManagement = Loadable(lazy(() => import('views/pages/userManagement')));

const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/catalog/countries',
            element: <Countries />
        },
        {
            path: '/catalog/cities',
            element: <Cities />
        },
        {
            path: '/catalog/areas',
            element: <Areas />
        },
        {
            path: '/catalog/service-categories',
            element: <ServiceCategories />
        },
        {
            path: '/catalog/services',
            element: <Services />
        },
        {
            path: '/catalog/blog-post-types',
            element: <BlogPostTypes />
        },
        {
            path: '/catalog/languages',
            element: <Languages />
        },
        {
            path: '/dashboard/offers',
            element: <Offers />
        },
        {
            path: '/dashboard/offer',
            element: <Offer />
        },
        {
            path: '/dashboard/requests',
            element: <Requests />
        },
        {
            path: '/dashboard/requests-contractor',
            element: <RequestsContractor />
        },
        {
            path: '/dashboard/request',
            element: <Request />
        },
        {
            path: '/dashboard/messages',
            element: <Messages />
        },
        {
            path: '/dashboard/timeline',
            element: <Timeline />
        },
        {
            path: '/dashboard/orders',
            element: <Orders />
        },
        {
            path: '/dashboard/order',
            element: <Order />
        },
        {
            path: '/dashboard/tickets',
            element: <TicketsUser />
        },
        {
            path: '/dashboard/ticket',
            element: <Ticket />
        },
        {
            path: '/catalog/users',
            element: <Users />
        },
        {
            path: '/catalog/become-contractor-requests',
            element: <BecomeContractorRequests />
        },
        {
            path: '/dashboard/become-contractor',
            element: <BecomeContractorRequest />
        },
        {
            path: '/dashboard/account-settings',
            element: <UserAccountSettings />
        },
        {
            path: '/dashboard/request-search',
            element: <RequestSearch />
        },
        {
            path: '/dashboard/orders-contractor',
            element: <OrdersContractor />
        },
        {
            path: '/dashboard/blog-posts',
            element: <BlogPosts />
        },
        {
            path: '/dashboard/blog-post',
            element: <BlogPost />
        },
        {
            path: '/dashboard/reviews',
            element: <Reviews />
        },
        {
            path: '/dashboard/review',
            element: <Review />
        },
        {
            path: '/catalog/tickets',
            element: <Tickets />
        },
        {
            path: '/catalog/global-settings',
            element: <GlobalSettings />
        },
        {
            path: '/payment-success',
            element: <PaymentSuccess />
        },
        {
            path: '/payment-failure',
            element: <PaymentFailure />
        },
        {
            path: '/user-management',
            element: <UserManagement />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
    ]
};

export default MainRoutes;
