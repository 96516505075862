import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import LangSelectSection from './LangSelectSection';
import AuxMenuSection from './AuxMenuSection';

// assets
import { IconMenu2, IconUser } from '@tabler/icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import CookieConsent from "react-cookie-consent";
import { useDispatch } from 'react-redux';
import { PERMISSIONS } from 'store/actions2';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const showNotifications = true;
    const showProfile = false;
    const showSearch = false;
    const navigate = useNavigate();
    const sessionLang = localStorage.getItem('allserv-lang') || 'en';
    const { i18n } = useTranslation(); 
    const mytoken = sessionStorage.getItem('mytoken');
    const permissions = sessionStorage.getItem('permissions');
    const dispatch = useDispatch();

    useEffect(() => {
        const mytoken = localStorage.getItem('mytoken');
        const permissions = localStorage.getItem('permissions');
        const username = localStorage.getItem('username');
        const userID = localStorage.getItem('userID');
        const token = localStorage.getItem('token');
        const photoURL = localStorage.getItem('photoURL');

        if(userID && mytoken) {
            sessionStorage.setItem('username', username);
            sessionStorage.setItem('userID', userID);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('photoURL', photoURL);
            sessionStorage.setItem('permissions', permissions);
            sessionStorage.setItem('mytoken', mytoken);
            dispatch({ type: PERMISSIONS, permissions });
        }

        i18n.changeLanguage(sessionLang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                { (mytoken && permissions === '5') && 
                    <ButtonBase className='drawer-switch' sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="21" />
                        </Avatar>
                    </ButtonBase>
                }
                { (!mytoken)? 
                    <AuxMenuSection />
                : null
                }
            </Box>

            {/* header search */}
            { showSearch && <SearchSection /> }
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            { showNotifications && 
                <NotificationSection />
            }
            
            { showProfile && <ProfileSection /> }
            { !mytoken &&
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', paddingRight: '0px', marginRight: '16px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={() => {navigate(`/login`);}}
                        color="inherit"
                    >
                        <IconUser stroke={1.5} size="21" />
                    </Avatar>
                </ButtonBase>
            }
            { mytoken && <ProfileSection /> }
            {(!mytoken || window.innerWidth > 600) &&
                <>
                <LangSelectSection />
                </>
            }
            
            {window.isWebView? null: <CookieConsent>This website uses cookies to enhance the user experience. Find more information <a style={{ color: 'lavender' }} href="/article/privacy">here.</a></CookieConsent> }
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
