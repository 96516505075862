import { useState } from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';

import i18next from "i18next";
// import { useTranslation } from 'react-i18next'

const languages = [
    { value: 'en', text: "EN" },
    { value: 'ru', text: "RU" },
    { value: 'el', text: "EL" }
]

// ==============================|| SEARCH INPUT ||============================== //

const LangSelectSection = () => {
    const sessionLang = localStorage.getItem('allserv-lang') || 'en';
    const [lang, setLang] = useState(sessionLang);
    // const { i18n } = useTranslation(); 

    const handleLangChange = e => { 
        if (lang !== e.target.value) {
            setLang(e.target.value);
            localStorage.setItem('allserv-lang',e.target.value);
            i18next.changeLanguage(e.target.value);
            // i18n.changeLanguage(e.target.value);
            window.dispatchEvent(new Event("lang_changed"));
        }
    }

    return (
        <>
            <FormControl variant="standard">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Language"
                onChange={handleLangChange}
                sx={{ height: '21', paddingLeft: 2 }}
                disableUnderline
              >
                {languages.map(item => {
                    return (<MenuItem key={item.value} 
                    value={item.value}>{item.text}</MenuItem>);
                })}
              </Select>
            </FormControl>
        </>
    );
};

export default LangSelectSection;
