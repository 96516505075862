import { lazy } from 'react';

// project imports
import FrontLayout from 'layout/FrontLayout';
import Loadable from 'ui-component/Loadable';

// homepage routing
const HomePage = Loadable(lazy(() => import('views/homepage')));
const OffersViewPage = Loadable(lazy(() => import('views/offers-view')));
const OfferViewPage = Loadable(lazy(() => import('views/offer-view')));
const BlogPostViewPage = Loadable(lazy(() => import('views/blog-post-view')));
const NotFoundPage = Loadable(lazy(() => import('views/not-found-page')));
const ReviewsViewPage = Loadable(lazy(() => import('views/reviews-view')));
const ArticleViewPage = Loadable(lazy(() => import('views/article-view')));
const AllBlogPostsViewPage = Loadable(lazy(() => import('views/all-blog-posts-view')));
const AllArticlesViewPage = Loadable(lazy(() => import('views/all-articles-view')));

// ==============================|| MAIN ROUTING ||============================== //

const FrontRoutes = {
    path: '/',
    element: <FrontLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/offers',
            element: <OffersViewPage />
        },
        {
            path: '/offer',
            element: <OfferViewPage />
        },
        {
            path: '/blog-post',
            element: <BlogPostViewPage />
        },
        {
            path: '/reviews',
            element: <ReviewsViewPage />
        },
        {
            path: '/article/:slug',
            element: <ArticleViewPage />
        },
        {
            path: '/all-blog-posts',
            element: <AllBlogPostsViewPage />
        },
        {
            path: '/all-articles',
            element: <AllArticlesViewPage />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default FrontRoutes;
